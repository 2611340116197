import React from "react";
import { Container, Row, Col } from "reactstrap";
import Link from "gatsby-link";
import Arrow from "../assets/images/right-arrow.svg";

const Services = () => {
    return (
        <div className="services">
        <div className="bgimage">
          <Container>
          <Row>
            <Col md="12 p-5">
              <h2>Our services</h2>
              <p className="mb-4">
              We offer an end to end building service, but can also work with your own architect or existing plans.<br/>You will have a single point of contact who manages your project.
              </p>
              <Link to="/services" className="cta p-3">
                View our services{" "}
                <img
                  src={Arrow}
                  className="arrow ml-2"
                  alt="View our services"
                />
              </Link>
            </Col>
          </Row>
          </Container>
        </div>
      </div>
    )
}
export default Services;