import React from "react";
import { Container, Row, Col } from "reactstrap";

const Testimonials = () => {
  return (
    <div className="testimonials">
      <Container className="p-5">
        <Row>
          <Col md="12 mb-3">
            <h2>Our testimonials</h2>
          </Col>
        </Row>
      
        <Row>
          <Col md="3 pl-3 testimonial-item">
            <p className="mb-0">
              The quality of workmanship was excellent and the resolution of
              details particularly well handled.<br/><strong>B Hunt, Farnham</strong>
            </p>
          </Col>
          <Col md="3 pl-3 testimonial-item">
            <p className="mb-0">
            Very pleased with the end result, excellent knowledge and experience and provided good advice.  I would highly recommend<br/><strong>Falconer, Fleet</strong>
            </p>
          </Col>
          <Col md="6 pl-3 testimonial-item">
            <p className="mb-0">
              Interbuilding Projects provided an excellent service from the
              design to the end of the build. We were kept informed throughout
              the project, the workmanship was outstanding and the site was kept
              tidy at all times. Without hesitation I would highly recommend
              them.<br/><strong>Hawkins, Fleet</strong>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Testimonials;