import React from "react";
import { Container, Row, Col } from "reactstrap";
import Link from "gatsby-link";
import Layout from "../components/layout";
import Arrow from "../assets/images/right-arrow.svg";
import ArrowGrey from "../assets/images/right-arrow2.svg";
import Master from "../assets/images/master.png";
import Testimonials from "../components/testimonials"
import Services from "../components/services"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Container className="hero homehero">
        <Row>
          <Col md="6 p-5 vh100">
            <h1 className="pt-5">Let us take care of your building project</h1>
            <h4 className="pt-3 pb-3">
              Professional building services with a personal touch
            </h4>
            <div className="action mt-5">
              <Link to="/services" className="cta p-3">
                View our services{" "}
                <img
                  src={Arrow}
                  className="arrow ml-2"
                  alt="View our services"
                />
              </Link>
              <Link to="/gallery" className="cta p-3 ml-3">
                View our gallery{" "}
                <img
                  src={Arrow}
                  className="arrow ml-2"
                  alt="View our gallery"
                />
              </Link>
            </div>
          </Col>
        </Row>
        </Container>
      <div className="greenarea">
        <Container>
        <Row>
          <Col md="12 py-4 px-5">
            <p>
              We are an independent family run building and renovation company
              based in Fleet, and for over 30 years we have been providing a
              wide range of high-end services to transform properties.
            </p>
            <p className="mb-0">
              Renowned for our personal approach, we offer a single point of
              contact to manage your project. Our experienced team of skilled
              tradesman have a conscientious attitude towards all aspect of the
              work we undertake whether the project is large of small.
            </p>
          </Col>
        </Row>
        </Container>
      </div>

      <Services/>
      
      <div className="master">
      <Container className="px-5">
        <div className="bgimage">
          <Row>
            <Col md="7 p-4">
              <p className="mb-4">
                As a member of the Federation of Master Builders we have meet
                their strict membership criteria and passed the vetting process.
                We also have public and employers’ liability insurance.
              </p>
              <a
                href="https://www.fmb.org.uk/member-builders/interbuilding-projects-ltd-gu52-6jb/"
                rel="noopener noreferrer"
                target="_blank"
                className="ctasmall"
              >
                Find out more{" "}
                <img
                  src={ArrowGrey}
                  className="arrow ml-2"
                  alt="Find out more"
                />
              </a>
            </Col>
            <Col md="5">
              <img
                src={Master}
                className="masters pt-4"
                alt="Master Builders"
              />
            </Col>
          </Row>
        </div>
      </Container>
      </div>

      <Testimonials/>
    </Layout>
  );
};

export default IndexPage;
